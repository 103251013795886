import moment from 'moment';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { IAdCampaign } from 'types/ad-campaign';

import { Tooltip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import ReactTable from 'creative-components/ReactTable/ReactTable';

import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';

import { enumToReadableName, numberWithCommas } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0px',
  },
  tableHeader: {
    background: '#F2F2F3',
  },
  tableBody: {

  },
  tableRow: {

  },
  tableHeaderCell: {
    textAlign: 'left',
    padding: '12px 16px',
    color: theme.palette.grayScale9.main,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  tableDataCell: {
    boxShadow: '0 -1px 0 #BEBEC5', // Use box shadow as small border
    textAlign: 'left',
    padding: '12px 14px',
    color: theme.palette.grayScale9.main,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  nameCell: {
    fontSize: '14px',
    lineHeight: '20px',
    '& > svg': {
      verticalAlign: 'bottom',
      marginRight: '4px',
    },
    '& > a': {
      font: 'inherit',
      color: 'inherit',
      textDecoration: 'underline',

      '&:hover': {
        color: theme.palette.orange.main,
      },
    },
  },

  cellWithIcon: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: '5px',
    },
  },
  linkUnderline: {
    '& span': {
      textDecoration: 'underline',
    },
  },
}));

interface Props {
  adCampaigns: IAdCampaign[];
  tableProps: any;
}

export default ({ adCampaigns, tableProps }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();

  const { isAdmin, isAccountManager, isBrokerageAdmin } = useAuthDataContext();

  // const columns: Column<MyData>[] = [
  const tableColumns = React.useMemo<Column<IAdCampaign>[]>(
    () => [
      {
        Header: 'Status',
        accessor: ({ status }: IAdCampaign) => enumToReadableName(status),
      },
      ...(isAdmin || isAccountManager || isBrokerageAdmin ? [
        {
          Header: 'User',
          Cell: ({ row, value }: React.PropsWithChildren<CellProps<IAdCampaign, any>>) => (<div>{`${row.original.user.firstName} ${row.original.user.lastName ?? ''}`}</div>),
        },
      ] : []),
      {
        Header: 'Ad Name',
        accessor: 'name',
        Cell: ({ row, value }: React.PropsWithChildren<CellProps<IAdCampaign, any>>) => (
          <div className={classes.nameCell}>
            <Link to={{
              pathname: `/dashboard/ad-campaigns/${row.original._id}`,
              state: { previousPathname: location.pathname },
            }}
            >
              {value}
            </Link>
          </div>
        ),
      },
      {
        Header: 'Reach',
        disableFilters: true,
        Cell: ({ row, value }: React.PropsWithChildren<CellProps<IAdCampaign, any>>) => (
          <Tooltip title="The number of unique accounts that saw your ads at least once." TransitionComponent={React.Fragment}>
            <span>{row.original.stats.reach ? numberWithCommas(row.original.stats.reach) : 'N/A'}</span>
          </Tooltip>
        ),
      },
      {
        Header: 'Impressions',
        disableFilters: true,
        Cell: ({ row, value }: React.PropsWithChildren<CellProps<IAdCampaign, any>>) => (
          <Tooltip title="The number of times your ads were on screen." TransitionComponent={React.Fragment}>
            <span>{row.original.stats.impressions ? numberWithCommas(row.original.stats.impressions) : 'N/A'}</span>
          </Tooltip>
        ),
      },
      {
        Header: 'Clicks',
        disableFilters: true,
        Cell: ({ row, value }: React.PropsWithChildren<CellProps<IAdCampaign, any>>) => (
          <Tooltip title="The number of call-to-action button link clicks." TransitionComponent={React.Fragment}>
            <span>{row.original.stats.inlineLinkClicks ? numberWithCommas(row.original.stats.inlineLinkClicks) : 'N/A'}</span>
          </Tooltip>
        ),
      },
      {
        Header: 'Started On',
        disableFilters: true,
        accessor: ({ createdAt }: IAdCampaign) => moment(createdAt).format('MM/DD/YY'),
      },
    ],
    [],
  );

  return (
    <div className={classes.root}>
      <ReactTable
        columns={tableColumns}
        data={adCampaigns}
        {...tableProps}
      />
    </div>
  );
};
